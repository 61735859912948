interface Document {
  section: string;
  items: string[];
}

export const MANDATORY_DOCUMENT: Document = {
  section: "Mandatory Documents",
  items: [
    "Nonimmigrant Visa Online Application (DS-160 Form) Confirmation",
    "Visa Interview Appointment Confirmation Letter",
    "Valid Passport (at least 6 months validity after your program end date)",
    "Passport Photo with dimensions of 2x2 inch (51x51mm). Check Photo Requirements",
    "Original I-20 from the University that you plan to attend",
    "SEVIS Fee Receipt",
    "Visa Interview Fee Payment Receipt (MRV Fee Receipt)",
  ],
};

export const SUPPORTING_DOCUMENTS: Document[] = [
  {
    section: "Academics Certificates, Letters, Test Scores",
    items: [
      "Bachelor's Degree Original Certificate",
      "Original Consolidated Mark Sheets of your Bachelor's degree",
      "Masters Degree Original Certificate",
      "10th Class, 11th and 12th Class (Intermediate) Original Marks Certificates",
      "Letter of Admission from the University that you plan to attend",
      "Financial Aid or Scholarship award letter from the university",
      "Test Scores Originals for GRE, TOEFL, GMAT, SAT",
    ],
  },
  {
    section: "Financial Documents",
    items: [
      "Bank Statements and Letter from the sponsor’s Bank account with proof of funds",
      "Loan approval letter for payment of university fee.",
      "Sponsors' Proof of Incomelike Salary Slips, or Business revenue in form of bank statements.",
      "if you have property and assets, Assessment of funds, property, and original copy of the same.",
      "if your sponsors or family are having any business or assets copies of the same to show the ties to come back.",
      "Investments details like Stocks, Mutual funds, etc. statements.",
    ],
  },
  {
    section: "Extracurricular, Previous Work Experience, Profile",
    items: [
      "Extra Curricular certificates that you used for scholarship or admission",
      "Work experience letters, Salary Slips, and Offer letters from your job",
      "Tax returns from previous years",
      "Resume",
    ],
  },
  {
    section: "Application Information, Additional Admits or Reject Letters",
    items: [
      "Copies of important documents like SOP, Recommendation Letters that you used in the application packet",
      "Additional Acceptance letters from other Universities.",
      "Reject letters from universities that did not offer you admission",
      "Copy of the financial support affidavit sent to the university with sponsor details",
      "Any email correspondence with faculty indicating a possibility for funding or assistant-ship",
    ],
  },
];
