import {
  Checkbox,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { SUPPORTING_DOCUMENTS, MANDATORY_DOCUMENT } from "../data/checklist";

export default function Intro() {
  return (
    <>
      <Typography variant="h3">Interview Checklist</Typography>
      <List
        subheader={
          <Typography variant="h6" gutterBottom>
            {MANDATORY_DOCUMENT.section}
          </Typography>
        }
      >
        {MANDATORY_DOCUMENT.items.map((doc) => (
          <ListItem>
            <Checkbox size="small" />
            <ListItemText primary={doc} />
          </ListItem>
        ))}
      </List>
      {SUPPORTING_DOCUMENTS.map((section) => (
        <List
          subheader={
            <Typography variant="h6" gutterBottom>
              {section.section}
            </Typography>
          }
        >
          {section.items.map((doc) => (
            <ListItem>
              <Checkbox size="small" />
              <ListItemText primary={doc} />
            </ListItem>
          ))}
        </List>
      ))}
    </>
  );
}
