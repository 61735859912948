import * as React from "react";
import "./App.css";
import Container from "@mui/material/Container";
import Questions from "./components/Questions";
import CheckList from "./components/CheckList";
import { Stack } from "@mui/material";
import Intro from "./components/Intro";

export default function App() {
  return (
    <Container maxWidth="xl" sx={{ border: "solid", borderColor: "pink" }}>
      <Stack spacing={4}>
        <Intro />
        <Questions />
        <CheckList />
      </Stack>
    </Container>
  );
}
