export function createData(
  question: string,
  answers: string[],
  categories: Category[],
  translation?: string,
  notes?: string
) {
  return {
    question,
    categories,
    answers,
    translation,
    notes,
  };
}

export enum Category {
  Personal = "Personal",
  Study = "Study Plans",
  University = "University Choice",
  Academic = "Academic Capability",
  Financial = "Financial",
  Graduation = "Post-graduation Plans",
  Culture = "Culture",
  Logistic = "Logistics",
}

export const CategoryColor = {
  [Category.Personal]: "pink",
  [Category.Study]: "green",
  [Category.University]: "blue",
  [Category.Academic]: "red",
  [Category.Financial]: "green",
  [Category.Graduation]: "orange",
  [Category.Culture]: "purple",
  [Category.Logistic]: "black",
};

export const rows = [
  {
    question:
      "Do you know anything about your parents\u2019 companies? What do they do?/What are their jobs?",
    translation:
      "B\u1ea1n c\u00f3 bi\u1ebft b\u1ea5t k\u1ef3 th\u00f4ng tin g\u00ec v\u1ec1 c\u00f4ng ty c\u1ee7a ba/m\u1eb9 m\u00ecnh kh\u00f4ng? B\u1ed1/m\u1eb9 b\u1ea1n l\u00e0m c\u00f4ng vi\u1ec7c g\u00ec?",
    categories: [Category.Personal, Category.Financial],
    answers: [
      `Yes. My dad works for <insert business name> as a <insert profession> and my mom is a <insert profession> at <insert business name>.\
      My dad'\s company is a leader in the <insert industry> industry with X number of employees.
      My mom'\s company was founded in <insert year> and is currently doing about <insert dollar amount> of business a year in <insert industry>.
      `,
      "Both of my parents work for the government. My dad works for the <insert gov branch>, while my mom works in the <insert gov branch>.",
    ],
  },
  {
    question: "Have your parents/ your siblings traveled abroad?",
    translation:
      "Cha m\u1eb9/Anh ch\u1ecb c\u1ee7a b\u1ea1n tr\u01b0\u1edbc \u0111\u00e2y \u0111\u00e3 t\u1eebng ra n\u01b0\u1edbc ngo\u00e0i ch\u01b0a?",
    categories: [Category.Personal, Category.Financial],
    answers: [
      "No, they have not due to busy work and study schedule. We are planning a family trip to <insert country> for the next <insert holiday> now that Covid is over and my siblings are done with school.",
      "Yes, my entire family have traveled quite a bit. Especially before Covid when most countries were still open. Our favorite destination is <insert country>, since we try to go back yearly.",
    ],
  },
  {
    question:
      "Is any of your siblings studying in the US? Why don't they study abroad like you?",
    translation:
      "B\u1ea1n c\u00f3 anh/ch\u1ecb n\u00e0o \u0111ang h\u1ecdc t\u1eadp \u1edf n\u01b0\u1edbc M\u1ef9 kh\u00f4ng? T\u1ea1i sao anh/ch\u1ecb b\u1ea1n l\u1ea1i kh\u00f4ng ra n\u01b0\u1edbc ngo\u00e0i h\u1ecdc t\u1eadp nh\u01b0 b\u1ea1n?",
    categories: [Category.Personal, Category.Financial],
    answers: [
      "My sibling is a lot more shy than I am and they feel more comfortable being in our home country.",
      "My sibling thrives in the Vietnamese education system where memorization rules.",
    ],
  },
  {
    question: "Do you have any relatives and friends in the US?",
    translation:
      "B\u1ea1n c\u00f3 ng\u01b0\u1eddi th\u00e2n hay b\u1ea1n b\u00e8 n\u00e0o \u1edf M\u1ef9 kh\u00f4ng?",
    categories: [Category.Personal],
    answers: [],
    notes:
      "Respond truthfully. Tell the consulate officer about your distant relatives, even if you rarely see them.",
  },

  {
    question: "What grade are you studying in? What\u2019s your grade?",
    translation: "B\u1ea1n \u0111ang h\u1ecdc l\u1edbp m\u1ea5y?",
    categories: [Category.Academic],
    answers: [],
  },
  {
    question: "What are your test scores (GRE, GMAT, SAT, TOEFL, IELTS)?",
    translation:
      "\u0110i\u1ec3m thi GRE, GMAT, SAT, TOEFL, IELTS bao nhi\u00eau?)",
    categories: [Category.Academic],
    answers: [],
  },
  {
    question: "Why did you choose your major in the current/previous school?",
    translation:
      "T\u1ea1i sao b\u1ea1n ch\u1ecdn h\u1ecdc chuy\u00ean ng\u00e0nh n\u00e0y t\u1ea1i tr\u01b0\u1eddng? B\u1ea1n c\u00f3 mu\u1ed1n thay \u0111\u1ed5i kh\u00f4ng?",
    categories: [Category.Academic],
    answers: [],
  },
  {
    question: "Why not study in another country like Canada, or the UK?",
    translation:
      "T\u1ea1i sao b\u1ea1n kh\u00f4ng ch\u1ecdn \u0111i du h\u1ecdc t\u1ea1i Canada, Australia hay UK?",
    categories: [Category.University],
    answers: [],
    notes: `Don't answer with "US is a powerful nation" or "because it has a robust or established economy." These responses will give the impression that you want to settle in the US after graduation. Focus more of your conversation on the institution or university you plan to attend. You can cite professors who are well-known experts in their fields and teach at that university. You may also include some of its standout characteristics, such as its global ranking, its research capabilities, its faculty, and alumni profiles, etc.`,
  },
  {
    question:
      "How will you manage the cultural and educational differences in the US?",
    translation:
      "B\u1ea1n s\u1ebd gi\u1ea3i quy\u1ebft v\u1ea5n \u0111\u1ec1 kh\u00e1c bi\u1ec7t v\u1ec1 v\u0103n h\u00f3a nh\u01b0 th\u1ebf n\u00e0o khi \u0111i du h\u1ecdc M\u1ef9?",
    categories: [Category.Culture],
    answers: [],
  },
  {
    question: "Why did you choose this school?",
    translation:
      "H\u00e3y cho ch\u00fang t\u00f4i bi\u1ebft l\u00fd do m\u00e0 b\u1ea1n ch\u1ecdn h\u1ecdc t\u1ea1i ng\u00f4i tr\u01b0\u1eddng n\u00e0y m\u00e0 kh\u00f4ng ph\u1ea3i tr\u01b0\u1eddng kh\u00e1c?",
    categories: [Category.University],
    answers: [],
  },
  {
    question:
      "What is the address of the university? What city will you arrive in? Tell me something about the city that you will live in the US?",
    translation:
      "\u0110\u1ecba ch\u1ec9 tr\u01b0\u1eddng b\u1ea1n theo h\u1ecdc? B\u1ea1n s\u1ebd h\u1ecdc \u1edf th\u00e0nh ph\u1ed1 n\u00e0o? B\u1ea1n bi\u1ebft g\u00ec v\u1ec1 th\u00e0nh ph\u1ed1 m\u00e0 b\u1ea1n s\u1ebd h\u1ecdc khi t\u1edbi M\u1ef9?",
    categories: [Category.University],
    answers: [],
  },
  {
    question: "Can I see your high school/college diploma?",
    translation:
      "T\u00f4i c\u00f3 th\u1ec3 xem b\u1eb1ng c\u1ea5p c\u1ee7a b\u1ea1n kh\u00f4ng?",
    categories: [Category.Academic],
    answers: [],
  },
  {
    question:
      "How did you find out about this school? Did anyone help you during the exploration process?",
    translation:
      "B\u1ea1n bi\u1ebft v\u1ec1 tr\u01b0\u1eddng n\u00e0y b\u1eb1ng c\u00e1ch n\u00e0o?",
    categories: [Category.University],
    answers: [],
  },
  {
    question: "How did you get the I-20/DS-2019?",
    translation:
      "B\u1ea1n \u0111\u00e3 xin I-20/DS-2019 nh\u01b0 th\u1ebf n\u00e0o?",
    categories: [Category.University],
    answers: [],
  },
  {
    question: "When will your school start?",
    translation:
      "Khi n\u00e0o tr\u01b0\u1eddng b\u1ea1n s\u1ebd khai gi\u1ea3ng?",
    categories: [Category.University],
    answers: [],
  },
  {
    question: "How long do you want to study in the US?",
    translation:
      "B\u1ea1n mu\u1ed1n \u1edf M\u1ef9 h\u1ecdc trong bao l\u00e2u?",
    categories: [Category.University],
    answers: [],
  },
  {
    question: "What is the tuition fee? How about living cost?",
    translation:
      "H\u1ecdc ph\u00ed c\u1ee7a b\u1ea1n bao nhi\u00eau? Chi ph\u00ed sinh ho\u1ea1t nh\u01b0 th\u1ebf n\u00e0o?",
    categories: [Category.University],
    answers: [],
    notes:
      "Tell the consular how much your education will cost and how much you will need to spend on housing and other costs. Describe to them your monthly income and make an effort to convince them that it will be sufficient to pay for your courses. Do not mention the intention of working on campus because that should not be a part of your funding.",
  },
  {
    question: "Have you paid the program fee? If so, how?",
    translation:
      "B\u1ea1n \u0111\u00e3 n\u1ed9p h\u1ecdc ph\u00ed ch\u01b0a? N\u1ed9p b\u1eb1ng c\u00e1ch n\u00e0o?",
    categories: [Category.University],
    answers: [],
  },
  {
    question:
      "Where will you live in the US? With whom will you stay in the US?",
    translation:
      "B\u1ea1n s\u1ebd s\u1ed1ng t\u1ea1i n\u01a1i n\u00e0o \u1edf M\u1ef9? S\u1ed1ng c\u00f9ng ai)",
    categories: [Category.Logistic],
    answers: [],
  },
  {
    question: "Who will pick you up at the airport upon your arrival?",
    translation:
      "Ai s\u1ebd \u0111\u01b0a \u0111\u00f3n b\u1ea1n t\u1ea1i s\u00e2n bay",
    categories: [Category.Logistic],
    answers: [],
  },
  {
    question: "What difficulties do you think you may encounter in the US?",
    translation:
      "B\u1ea1n ngh\u0129 t\u1edbi nh\u1eefng kh\u00f3 kh\u0103n n\u00e0o khi \u0111i du h\u1ecdc M\u1ef9?)",
    categories: [Category.Culture, Category.Logistic],
    answers: [],
  },
  {
    question: "What are the university requirements for applicant?",
    translation:
      "Tr\u01b0\u1eddng b\u1ea1n ch\u1ecdn c\u00f3 nh\u1eefng y\u00eau c\u1ea7u nh\u1eadp h\u1ecdc g\u00ec?",
    categories: [Category.University],
    answers: [],
  },
  {
    question: "What school will you enroll in the US?",
    translation:
      "B\u1ea1n s\u1ebd h\u1ecdc t\u1ea1i tr\u01b0\u1eddng n\u00e0o khi \u0111\u1ebfn M\u1ef9?",
    categories: [Category.University],
    answers: [],
  },
  {
    question: "How many colleges did you apply to?",
    translation:
      "B\u1ea1n \u0111\u00e3 \u0111\u0103ng k\u00fd v\u00e0o m\u1ea5y tr\u01b0\u1eddng \u0111\u1ea1i h\u1ecdc r\u1ed3i?",
    categories: [Category.Academic, Category.University],
    answers: [
      "I applied to 5 universities across the country. Everyone of which has my major of interest and offer the same amount of financial aids.",
      "I only applied to this school because I strongly believe in their quality and I was confident I would get accepted.",
    ],
    notes:
      "The officer wants to learn more about your ability student and prospective employee. Students accepted into colleges with higher standards might have better chances of getting a visa. However, if they ask how many universities you were turned down by before this one honest. The interviewer can tell if you're lying, which might result in the rejection of your visa application.",
  },
  {
    question: "Is your school a public or a private school? ",
    translation:
      "Tr\u01b0\u1eddng c\u1ee7a b\u1ea1n l\u00e0 tr\u01b0\u1eddng c\u00f4ng hay tr\u01b0\u1eddng t\u01b0?",
    categories: [Category.University],
    answers: [],
    notes:
      "Research the differences between types of institution in the US and know which one your school is.",
  },
  {
    question: "What will you major in when you study university?",
    translation:
      "Chuy\u00ean ng\u00e0nh b\u1ea1n ch\u1ecdn \u1edf \u0111\u1ea1i h\u1ecdc s\u1ebd l\u00e0 g\u00ec?",
    categories: [Category.Study],
    answers: [],
  },
  {
    question: "What degree will you get after your graduation? ",
    translation:
      "B\u1ea1n s\u1ebd nh\u1eadn \u0111\u01b0\u1ee3c h\u1ecdc v\u1ecb g\u00ec khi t\u1ed1t nghi\u1ec7p?",
    categories: [Category.Study],
    answers: [
      "Bachelor of Science/Asrts in <insert major>",
      "Associate degree in <insert major>",
      "Master of Science in <insert major>",
    ],
  },
  {
    question: "Have you bought health insurance?",
    translation: "B\u1ea1n \u0111\u00e3 mua bảo hiểm y tế hay ch\u01b0a?",
    categories: [Category.Logistic],
    answers: [],
    notes:
      "Many international students may not be able to pay healthcare costs in the US. You could present proof of health insurance to persuade the interviewer that you will be able to support yourself financially throughout your stay in the US.",
  },
  {
    question: "Did you get offered a scholarship at your school?",
    translation:
      "B\u1ea1n c\u00f3 nh\u1eadn \u0111\u01b0\u1ee3c h\u1ecdc b\u1ed5ng kh\u00f4ng?",
    categories: [Category.Academic, Category.Financial],
    answers: [],
  },
  {
    question: "Who sponsors you during your study in the US? ",
    translation:
      "B\u1ea1n \u0111\u01b0\u1ee3c ai t\u00e0i tr\u1ee3 cho du h\u1ecdc?",
    categories: [Category.Financial],
    answers: [],
    notes:
      "The purpose of these questions is to help the interviewer understand how you intend to pay for your stay in the US. Present your savings to the interviewer if you have enough money to cover everything. In the absence of a sponsor, such as parents, relatives, a partner, etc., you must explain how and whether the sponsor can pay for your stay in the United States (scholarships, loans, etc.)",
  },
  {
    question: "What are your parents\u2019 salaries? ",
    translation:
      "L\u01b0\u01a1ng/thu nh\u1eadp c\u1ee7a ba m\u1eb9 b\u1ea1n nh\u01b0 th\u1ebf n\u00e0o?",
    categories: [Category.Financial],
    answers: [],
  },
  {
    question: "Besides salaries, do your parents have other sources of income?",
    translation:
      "Ngo\u00e0i l\u01b0\u01a1ng, ba/m\u1eb9 b\u1ea1n c\u00f3 c\u00f2n ngu\u1ed3n thu nh\u1eadp n\u00e0o kh\u00e1c?",
    categories: [Category.Financial],
    answers: [],
  },
  {
    question: "Do your families own any other properties?",
    translation:
      "Gia \u0111\u00ecnh b\u1ea1n c\u00f3 s\u1edf h\u1eefu b\u1ea5t \u0111\u1ed9ng s\u1ea3n n\u00e0o kh\u00f4ng?)",
    categories: [Category.Financial],
    answers: [],
  },
  {
    question: "Do you have a copy of your bank statements?",
    translation:
      "B\u1ea1n c\u00f3 \u0111em theo sao k\u00ea ng\u00e2n h\u00e0ng?",
    categories: [Category.Financial],
    answers: [],
  },
  {
    question:
      "What will you do if your parents ran out of money and could not afford your study anymore?",
    translation:
      "N\u1ebfu ba m\u1eb9 b\u1ea1n kh\u00f4ng c\u00f2n \u0111\u1ee7 \u0111i\u1ec1u ki\u1ec7n \u0111\u1ec3 t\u00e0i tr\u1ee3 b\u1ea1n du h\u1ecdc th\u00ec b\u1ea1n s\u1ebd l\u00e0m g\u00ec?",
    categories: [Category.Financial],
    answers: [],
  },
  {
    question:
      "How do you plan to fund the entire duration of your education?\u00a0",
    translation:
      "K\u1ebf ho\u1ea1ch t\u00e0i tr\u1ee3 du h\u1ecdc c\u1ee7a b\u1ea1n nh\u01b0 th\u1ebf n\u00e0o?",
    categories: [Category.Financial],
    answers: [],
    notes:
      "The purpose of these questions is to help the interviewer understand how you intend to pay for your stay in the US. Present your savings to the interviewer if you have enough money to cover everything. In the absence of a sponsor, such as parents, relatives, a partner, etc., you must explain how and whether the sponsor can pay for your stay in the United States (scholarships, loans, etc.)",
  },
  {
    question: "How much do you have to pay for your study in the US? ",
    translation: "T\u1ed5ng chi ph\u00ed du h\u1ecdc c\u1ee7a b\u1ea1n?",
    categories: [Category.Financial],
    answers: [],
  },
  {
    question: "Will you return to Vietnam when you finish studying?",
    translation:
      "Sau khi h\u1ecdc xong b\u1ea1n s\u1ebd quay v\u1ec1 Vi\u1ec7t Nam ch\u1ee9?",
    categories: [Category.Graduation],
    answers: [],
  },
  {
    question: "How can you prove that you will return to Vietnam? ",
    translation:
      "B\u1ea1n l\u00e0m sao ch\u1ee9ng minh h\u1ecdc xong s\u1ebd quay v\u1ec1 Vi\u1ec7t Nam?",
    categories: [Category.Graduation],
    answers: [],
  },
  {
    question: "What will you do after you finish your study in the US?",
    translation: "B\u1ea1n s\u1ebd l\u00e0m g\u00ec sau khi h\u1ecdc xong?",
    categories: [Category.Graduation],
    answers: [],
  },
  {
    question: "Do you have a job or career in mind after you graduate? ",
    translation:
      "B\u1ea1n c\u00f3 d\u1ef1 \u0111\u1ecbnh l\u00e0m g\u00ec sau khi t\u1ed1t nghi\u1ec7p?",
    categories: [Category.Graduation],
    answers: [],
  },
  {
    question:
      "How long do you intend to stay in the US after you finish your study?",
    translation:
      "B\u1ea1n c\u00f3 \u00fd \u0111\u1ecbnh \u1edf M\u1ef9 ti\u1ebfp bao l\u00e2u n\u1eefa sau khi t\u1ed1t nghi\u1ec7p?",
    categories: [Category.Graduation],
    answers: [],
  },
  {
    question: "Do you intend to work in the US?",
    translation:
      "B\u1ea1n c\u00f3 \u00fd \u0111\u1ecbnh l\u00e0m vi\u1ec7c t\u1ea1i M\u1ef9 hay kh\u00f4ng?)",
    categories: [Category.Graduation],
    answers: [],
  },
  {
    question: "Why should you be given a student visa?",
    translation: "Tại sao bạn nên được cấp thị thực du học?",
    categories: [Category.Study],
    answers: [],
    notes:
      "Make a good case for why you deserve a visa. Be confident and attempt to create a compelling case. Assure the consular that you do not have any intention of staying in America, and that you will undoubtedly return home.",
  },
  {
    question: "Will you come back to home during vacations/holidays?",
    translation: "Bạn sẽ trở về nhà trong các kỳ nghỉ/ngày lễ?",
    categories: [Category.Study],
    answers: [],
    notes:
      "The immigration officer is interested in learning about your ties to your family and home country. Let them know you'll be returning home during the holidays to see your loved ones. Do not mention to the interviewer that you want to work while staying in the country in summer or winter breaks. They will think that you are going to the US to work and that you might stay there even after you finish your courses.",
  },
];
