import * as React from "react";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { visuallyHidden } from "@mui/utils";

import {
  Stack,
  Chip,
  List,
  ListItem,
  ListItemText,
  Typography,
  TablePagination,
  TableSortLabel,
  Box,
} from "@mui/material";
import { createData, rows, CategoryColor } from "../data/questions";
import { getComparator, stableSort } from "../data/utils";

function Row(props: { row: ReturnType<typeof createData> }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const shouldShowCollapse = Boolean(row.answers.length || row.notes);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell padding="checkbox" size="small">
          {shouldShowCollapse ? (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}{" "}
            </IconButton>
          ) : null}
        </TableCell>
        <TableCell>{row.question}</TableCell>
        <TableCell>{row.translation}</TableCell>
        <TableCell>
          <Stack direction="row" spacing={1}>
            {row.categories.map((category) => {
              return (
                <Chip
                  label={category}
                  sx={{
                    borderColor: CategoryColor[category],
                  }}
                  variant="outlined"
                />
              );
            })}
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          {shouldShowCollapse ? (
            <Collapse in={open}>
              <List>
                {row.notes && (
                  <ListItem>
                    <ListItemText primary="Notes" secondary={row.notes} />
                  </ListItem>
                )}
                {row.answers.map((answer, index) => (
                  <ListItem>
                    <ListItemText
                      primary={`Answer template #${index + 1}`}
                      secondary={answer}
                    />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          ) : null}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: string;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  const HEAD_CELLS = [
    {
      id: "question",
      label: "Question",
    },
    {
      id: "tieng-viet",
      label: "Tiếng Việt",
    },
    {
      id: "categories",
      label: "Categories",
    },
  ];

  return (
    <TableHead>
      <TableRow>
        <TableCell />
        {HEAD_CELLS.map((headCell) => (
          <TableCell
            key={headCell.id}
            // @ts-ignore
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              // @ts-ignore
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function Questions() {
  const [order, setOrder] = React.useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = React.useState<string>("question");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    console.log("property", orderBy, property);
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  return (
    <>
      <Typography variant="h3" gutterBottom>
        Question bank
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />

          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                // @ts-ignore
                <Row key={row.question} row={row} />
              ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={rows.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}
