import { Typography } from "@mui/material";

export default function Intro() {
  return (
    <>
      <Typography variant="h3">Intro</Typography>
      <Typography variant="body1">
        The most crucial component of your F1 visa application is the interview.
        The F1 visa interview is required so that the consular officer may learn
        more about you as a candidate beyond what your paperwork indicates and
        determine if you are interested in studying in the United States or
        whether you have another motive for applying. You must therefore be
        ready for the interview in advance. Along with being on time, trying not
        to be apprehensive, and dressing appropriately, you should research
        frequently asked questions and attempt to prepare responses in advance
        of the interview.
      </Typography>
      <Typography variant="body1">
        These questions mean to give you a taste of the possible directions your
        visa interview can take. Feel free to add your flare to the answer
        templates. Good luck!
      </Typography>
    </>
  );
}
